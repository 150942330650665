<template>
  <div
    class="share-box"
    :class="{
      'frosted-background-static': $isSafari,
      'frosted-background': !$isSafari,
    }"
  >
    <span class="e0_partage_lien_titre">
      {{ $t("sketchy.page_jeu.player_list.share_link") }}
    </span>

    <input
      type="text"
      class="input is-small e0_partage_lien_input"
      :value="windowLocationOrigin() + '/#/salon/' + id_salon"
      style="min-width: 245px;"
      ref="gameLink"
      @focus="$event.target.select()"
      @click="$event.target.select()"
      @copy="eventCopyLink"
    />

    <div class="e0_partage_lien_bouton_copier">
      <BoutonCopy @click="clicBoutonCopierLien"/>

      <div class="tag-container" v-if="afficherTagCopieOk">
        <span class="tag is-success">{{ $t('sketchy.page_jeu.player_list.share_link_copied') }}</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'E0BoxPartageLien',

  data() {
    return {
      afficherTagCopieOk: false,
      afficherTagCopieOkTimeout: null,
    };
  },

  methods: {
    windowLocationOrigin() {
      return window.location.origin;
    },

    eventCopyLink() {
      this._matomo_trackGoal(this.MTM_GOAL_GAME_COPY_LINK);
    },

    clicBoutonCopierLien() {
      this._matomo_trackGoal(this.MTM_GOAL_GAME_BTN_SHARE);

      if ('share' in window.navigator && window.navigator.share) {
        if (window.navigator.userAgent.indexOf("Edg") > -1) {
          // https://stackoverflow.com/questions/61496955/why-does-edge-have-window-navigator-share-if-it-doesnt-support-it
          this.copierLien();
          return;
        }

        window.navigator.share({
          title: 'Sketchy',
          text: this.$t('sketchy.page_jeu.player_list.share_api_text') + '',
          url: this.windowLocationOrigin() + '/#/salon/' + this.id_salon,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => this.copierLien());
      } else {
        this.copierLien();
      }
    },

    copierLien() {
      if (window.navigator.clipboard) {
        const link = this.windowLocationOrigin() + '/#/salon/' + this.id_salon;
        window.navigator.clipboard.writeText(link).then(() => {
          this.afficheTagCopieOk();
        }, () => {
          this.copierLienLegacy();
        });
      } else {
        this.copierLienLegacy();
      }
    },

    copierLienLegacy() {
      // Get the text field
      let copyText = this.$refs.gameLink;

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      document.execCommand('copy');

      this.afficheTagCopieOk();
    },

    afficheTagCopieOk() {
      if (this.afficherTagCopieOk && this.afficherTagCopieOkTimeout !== null) {
        clearTimeout(this.afficherTagCopieOkTimeout);
        this.afficherTagCopieOkTimeout = null;

        this.afficherTagCopieOk = false;
        setTimeout(this.forceAfficheTagCopieOk, 90);
      } else {
        this.forceAfficheTagCopieOk();
      }
    },

    forceAfficheTagCopieOk() {
      this.afficherTagCopieOk = true;
      this.afficherTagCopieOkTimeout = setTimeout(() => {
        this.afficherTagCopieOkTimeout = null;
        this.afficherTagCopieOk = false;
      }, 650);
    }
  },

  components: {
    BoutonCopy: () => import('@/views/components/global/boutons/BoutonCopy'),
  },

  props: ['id_salon'],
}
</script>

<style lang="scss" scoped>
.share-box {
  position: relative;


  font-family: cabin_sketch_regular;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  padding-bottom: 50px;

  .e0_partage_lien_titre {
    position: relative;
    //background-color: red;
    text-align: center;
  }

  .e0_partage_lien_input {
    position: absolute;

    bottom: 16px;
    left: 20px;
    width: calc(100% - 20px - 55px);
  }

  .e0_partage_lien_bouton_copier {
    position: absolute;
    //background-color: #f0f;

    bottom: 8px;
    right: 16px;

    .tag-container {
      position: absolute;
      bottom: -20px;
      //left: 0;
      right: 0;
      width: 200%;
      left: -50%;
      height: 24px;

      text-align: center;

      //background-color: orange;

      .tag {
        display: inline-block;
        line-height: 25px;
      }
    }
  }
}
</style>
